import React from 'react';
import { Alert, Text } from '@chakra-ui/react';

export function MessageContainer({
  message,
  status,
  children,
}: {
  message?: string;
  status?: 'success' | 'warning' | 'error';
  children?: React.ReactNode;
}) {
  if (!status) {
    return <></>;
  }

  let bgColor;
  let textColor;
  switch (status) {
    case 'success':
      bgColor = 'alertsSuccess';
      textColor = 'primaryDark';
      break;

    case 'warning':
      bgColor = 'alertWarning';
      textColor = 'statusHighlight';
      break;

    case 'error':
      bgColor = 'alertDanger';
      textColor = 'statusAlert';
      break;
  }

  return (
    <Alert
      status={status}
      mt={4}
      mb={4}
      borderRadius={5}
      bgColor={bgColor}
      textColor={textColor}
    >
      <>
        {!!message && <Text>{message}</Text>}
        {children}
      </>
    </Alert>
  );
}
