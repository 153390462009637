import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  useColorMode,
  VStack,
} from '@chakra-ui/react';
import {
  AuthProvider,
  FacebookAuthProvider,
  getAdditionalUserInfo,
  getAuth,
  getRedirectResult,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import type { GetServerSideProps, NextPage } from 'next';
import NextHead from 'next/head';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import getConfig, { getPublicRuntimeConfig } from '../common/config';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import { title } from '../common/libs/metadata';
import ExternalLink from '../components/ExternalLink';
import { MessageContainer } from '../components/MessageContainer';
import PasswordInput from '../components/PasswordInput';
import { useAppContext } from '../contexts/app';
import useApiClient from '../hooks/useApiClient';
import useIdApiClient from '../hooks/useIdApiClient';
import {
  AuthErrorCond,
  AuthErrorCondVal,
  AuthErrorSubCond,
  AuthErrorSubCondVal,
} from '../server/libs/error';
import withAuth from '../server/libs/withAuth';

type FormValues = {
  username?: string;
  password?: string;
  token?: string;
};

type ValidationErrors = {
  [key in NonNullable<keyof FormValues>]: string | null;
};

function PageHead() {
  const { slideWebBaseUrl } = getPublicRuntimeConfig();
  const loginUrl = `${slideWebBaseUrl}/login`;
  return (
    <NextHead>
      <title>{title('ログイン')}</title>
      <meta
        name="description"
        content="Antaa Slideにログインして、より便利にスライドを閲覧・共有しましょう。"
      />
      <link rel="canonical" href={loginUrl} />
    </NextHead>
  );
}

const SigninButton = chakra(
  ({
    type = 'button',
    colorScheme,
    variant,
    disabled,
    children,
    className,
    isLoading,
    onClick,
  }) => {
    return (
      <Button
        type={type}
        colorScheme={colorScheme}
        variant={variant}
        isDisabled={disabled}
        w="100%"
        fontSize="md"
        p={6}
        className={className}
        isLoading={isLoading ?? false}
        onClick={onClick}
        _hover={{ opacity: 0.8 }}
        _disabled={{ opacity: 0.6 }}
      >
        {children}
      </Button>
    );
  },
);

const LoginPage: NextPage = () => {
  const [errorCond, setErrorCond] = useState<AuthErrorCond>(null);
  const [errorSubCond, setErrorSubCond] = useState<AuthErrorSubCond>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const router = useRouter();
  const { apiClient } = useApiClient();
  const { idApiClient, proceedIdPage } = useIdApiClient();
  const { csrfToken } = useAppContext();

  const mailAddrParam = router.query.mailAddr;
  const mailAddr = Array.isArray(mailAddrParam)
    ? mailAddrParam[0]
    : mailAddrParam;

  const accountIntegrationCompleted =
    router.query.account_integration_completed === 'true';

  const { colorMode } = useColorMode();

  const doSubmit = useCallback(
    async (values: FormValues) => {
      // console.log('-- doSubmit --');
      try {
        // Slideサービスログイン
        const res2 = await apiClient('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });
        if (res2.ok) {
          if (values.token) {
            // ID基盤ログイン
            const res = await idApiClient('login', {
              method: 'POST',
            });
            // eslint-disable-next-line no-console
          }
          setIsLoading(false);

          const redirectUrl = router.query.redirectUrl;
          if (typeof redirectUrl === 'string' && redirectUrl.startsWith('/')) {
            router.push(redirectUrl);
          } else {
            router.push('/');
          }
        } else {
          setIsLoading(false);
          const body = await res2.json();
          const messageInfo = body.resultInfo.messageInfo;
          /* console.error(
            `message = ${messageInfo.message}; cond = ${messageInfo.cond}`,
          );
          */
          setErrorCond(messageInfo.cond);
          setErrorSubCond(messageInfo?.subCond);
        }
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setErrorCond(AuthErrorCondVal.ERROR);
      }
    },
    [idApiClient, apiClient, router],
  );

  useEffect(() => {
    if (!csrfToken) {
      return;
    }
    setIsLoading(true);
    getRedirectResult(getAuth())
      .then((result) => {
        if (!result) {
          // console.log('External provider authentication; no redirect called');
          setIsLoading(false);
          return;
        }

        const credential = OAuthProvider.credentialFromResult(result);
        if (credential) {
          const aui = getAdditionalUserInfo(result);
          if (aui?.isNewUser === true) {
            // 新規ユーザー作成時：アカウント登録を促すエラーメッセージ通知
            setIsLoading(false);
            setErrorCond(AuthErrorCondVal.NO_REGIST);
            return;
          }

          const user = result.user;
          user
            .getIdToken()
            .then((token) => {
              doSubmit({
                username: user.email || undefined,
                password: '',
                token,
              });
            })
            .catch((error) => {
              setIsLoading(false);
              console.error('Firebase authentication failed!!; error' + error);
              setErrorCond(AuthErrorCondVal.ERROR);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(
          'External provider authentication failed!!; error : ' + error,
        );
        setErrorCond(AuthErrorCondVal.ERROR);
      });
  }, [csrfToken, doSubmit]);

  const signInWithProvider = async (provider: AuthProvider) => {
    try {
      setIsLoading(true);

      // 2023.3.2 暫定無効化。popupログインに切り替えた。
      // signInWithRedirect(getAuth(), provider);

      const result = await signInWithPopup(getAuth(), provider);
      const credential = OAuthProvider.credentialFromResult(result);
      if (credential) {
        const aui = getAdditionalUserInfo(result);
        if (aui?.isNewUser) {
          // 新規ユーザー作成時：アカウント登録を促すエラーメッセージ通知
          setErrorCond(AuthErrorCondVal.NO_REGIST);
          return;
        }

        const user = result.user;
        const token = await user.getIdToken();
        doSubmit({
          username: user.email || undefined,
          password: '',
          token,
        });
      }
    } catch (e) {
      console.error('Firebase authentication failed!!; error' + e);
      setErrorCond(AuthErrorCondVal.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const signinWithFacebook = function () {
    // console.log('-- signinWithFacebook --');
    sendAnalyticsEvent('auth', 'login', 'facebook');

    const provider = new FacebookAuthProvider();
    signInWithProvider(provider);
  };

  const signinWithApple = function () {
    // console.log('-- signinWithApple --');
    sendAnalyticsEvent('auth', 'login', 'apple');

    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    provider.setCustomParameters({
      locale: 'ja_JP',
    });
    signInWithProvider(provider);
  };

  const signinWithEmail = async (email: string, password: string) => {
    // console.log('-- signinWithEmail --');
    sendAnalyticsEvent('auth', 'login', 'email');

    // Firebase（Antaaアカウント）で認証
    // console.log('-- Firebase authentication --');
    setIsLoading(true);
    signInWithEmailAndPassword(getAuth(), email, password)
      .then((userCredential) => {
        userCredential.user
          .getIdToken()
          .then((token) => {
            // アクセストークンでログイン
            doSubmit({ username: email, password, token });
          })
          .catch((e) => {
            setIsLoading(false);
            console.error('Firebase authentication failed!!; error' + e);
            setErrorCond(AuthErrorCondVal.ERROR);
          });
      })
      .catch((e) => {
        setIsLoading(false);
        console.error('Firebase authentication failed!!; error' + e);
        switch (e.code) {
          case 'auth/invalid-email':
          case 'auth/user-not-found':
          case 'auth/too-many-requests':
          case 'auth/user-disabled':
          case 'auth/wrong-password': // 時限措置としてSlideアカウントで認証を試みる
            // Firebaseに登録されていない場合は従来のメールアドレス＆パスワードで認証する
            // console.log('-- Email and password authentication --');
            doSubmit({ username: email, password });
            break;

          default:
            setErrorCond(AuthErrorCondVal.ERROR);
        }
      });
  };

  const onSubmit = (values: FormValues) => {
    // console.log(`-- onSubmit -- ; values = ${values}`);
    signinWithEmail(values.username!.trim(), values.password!);
  };

  const validate = (values: FormValues): ValidationErrors | undefined => {
    const errors: ValidationErrors = {
      username: null,
      password: null,
      token: null,
    };

    if (!values.username) {
      errors.username = 'メールアドレスを入力してください';
    }

    if (!values.password) {
      errors.password = 'パスワードを入力してください';
    }

    const hasNoError = Object.values(errors).every((v) => v === null);
    if (hasNoError) {
      return undefined;
    } else {
      return errors;
    }
  };

  const {
    publicRuntimeConfig: { qaWebBaseUrl, idBaseUrl },
  } = getConfig();

  /**
   * アカウント登録状態に応じてリダイレクト
   */
  useEffect(() => {
    switch (errorCond) {
      case AuthErrorCondVal.REGISTER_NOT_COMPLETE:
        // ユーザー登録が未完了のAntaaアカウントでログインしようとした場合は
        // ID基盤のアカウント登録エントリポイントにリダイレクト
        window.location.href = `${idBaseUrl}/signup?from_service=slide`; // アカウント登録は事前のID基盤ログインが不要なためproceedIdPageを使わない
        return;
      case AuthErrorCondVal.ACCOUNT_DISABLE:
        // 無効となっているAntaaアカウントでログインしようとした場合は
        // ID基盤の退会画面にリダイレクト
        window.location.href = `${idBaseUrl}/users/exited?from_service=slide`; // 退会済み画面は事前のID基盤ログインが不要なためproceedIdPageを使わない
        return;
      default:
        // 上記以外のケースではリダイレクトなし
        return;
    }
  }, [errorCond, idBaseUrl]);

  function LoginErrorMessage() {
    let message;
    switch (errorCond) {
      case null:
        return <></>;

      case AuthErrorCondVal.ERROR:
        message = <p>メールアドレスまたはパスワードが間違っています。</p>;
        break;

      case AuthErrorCondVal.REGISTER_NOT_COMPLETE:
      case AuthErrorCondVal.ACCOUNT_DISABLE:
        // これらのエラーの場合は各画面にリダイレクトさせるため
        // エラーメッセージは画面に表示しない
        return <></>;

      case AuthErrorCondVal.USE_INTEGRATED_ACCOUNT:
        message = (
          <p>
            お使いのアカウントは統合が完了しています。Antaaアカウント（Antaa
            QA／アプリでお使いのアカウント）でログインしてください。
          </p>
        );
        break;

      case AuthErrorCondVal.NO_REGIST:
        message = (
          <p>
            アカウントが登録されていません。
            <br />
            <Link href={qaWebBaseUrl} target="_blank" rel="noopener" isExternal>
              こちら <ExternalLinkIcon ml={1} mb={1} />
            </Link>
            から登録を行って下さい。
          </p>
        );
        break;

      case AuthErrorCondVal.FATAL:
      default:
        message = (
          <p>
            現在ご利用いただけません。
            <br />
            時間が経ってから再度お試し下さい。
          </p>
        );
        break;
    }

    return (
      <Box mb={4}>
        <Alert status="error">
          <AlertDescription>{message}</AlertDescription>
        </Alert>
      </Box>
    );
  }

  const initialFormValues: FormValues = {
    username: mailAddr,
  };

  const signupUrl = useMemo(() => {
    const redirectUrl = router.query.redirectUrl;
    const { idBaseUrl } = getPublicRuntimeConfig();
    const accountSignupUrl = `${idBaseUrl}/signup?from_service=slide`;

    if (typeof redirectUrl === 'string' && redirectUrl.startsWith('/')) {
      return `${accountSignupUrl}&redirect_url=${encodeURIComponent(
        redirectUrl,
      )}`;
    } else {
      return accountSignupUrl;
    }
  }, [router.query.redirectUrl]);

  function AccountIntegrationCompletedMessage() {
    return accountIntegrationCompleted ? (
      <MessageContainer status="success">
        <VStack alignItems="center" w="full">
          <Text fontSize="lg" fontWeight="bold" color="primaryDark">
            アカウント連携が完了しました！
          </Text>
          <Text>新しいログイン情報でログインしてください</Text>
        </VStack>
      </MessageContainer>
    ) : (
      <></>
    );
  }

  // お問い合わせ用googleフォーム
  const CONTACT_URL =
    'https://docs.google.com/forms/d/1Ei--dwswtZjKMTytAR3Zf9Cn3YYdVUL418Q933VXI-c/viewform';

  return (
    <>
      <PageHead />
      <Box mt={8} w={{ base: '90%', md: '60%' }} mx="auto">
        <Box display="flex" justifyContent="center">
          <NextImage
            src={
              colorMode === 'light'
                ? '/images/logo_slide.png'
                : '/images/logo_slide_white.png'
            }
            alt="Antaa Slide"
            width={246}
            height={50}
            objectFit="contain"
          />
        </Box>
        <Box mt={8}>
          <LoginErrorMessage />
          <AccountIntegrationCompletedMessage />
          <Box mb={4} bgColor={'#bee3f8'} p={3}>
            <Text>
              ※アカウント統合をされた方は、アカウント統合時に作成/使用したメールアドレスでログインしてください。
            </Text>
            <Text>
              メールアドレスがわからない方は、
              <ExternalLink href={CONTACT_URL}>こちら</ExternalLink>
              よりお問い合わせください。
            </Text>
          </Box>
          <Form
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, hasValidationErrors }) => (
              <VStack as="form" onSubmit={handleSubmit} spacing={4}>
                <Field name="username">
                  {({ input, meta }) => (
                    <FormControl
                      id="username"
                      isInvalid={meta.touched && meta.error}
                    >
                      <FormLabel>メールアドレス</FormLabel>
                      <Input
                        type="text"
                        {...input}
                        placeholder="メールアドレス"
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="password">
                  {({ input, meta }) => (
                    <FormControl
                      id="password"
                      isInvalid={meta.touched && meta.error}
                    >
                      <FormLabel>パスワード</FormLabel>
                      <PasswordInput {...input} />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <SigninButton
                  type="submit"
                  bg="brand.500"
                  color="white"
                  variant="outline"
                  disabled={hasValidationErrors}
                  mt={4}
                  isLoading={isLoading}
                >
                  メールアドレスでログイン
                </SigninButton>
                <NextLink href="/account/reissue" passHref>
                  <Link textDecoration="underline">
                    パスワードをお忘れの方はこちら
                  </Link>
                </NextLink>

                <ExternalLink
                  onClick={() =>
                    sendAnalyticsEvent('link_to', 'note', 'faq_page')
                  }
                  href="https://note.com/antaa/n/ne7676bee6c70"
                >
                  ログインに関するFAQ
                </ExternalLink>
              </VStack>
            )}
          />
        </Box>
        <VStack spacing={4} mt={6}>
          <SigninButton
            color="white"
            bg="facebook.500"
            onClick={() => signinWithFacebook()}
            isLoading={isLoading}
          >
            Facebookでログイン
          </SigninButton>
          <SigninButton
            color="white"
            bg="black"
            onClick={() => signinWithApple()}
            isLoading={isLoading}
          >
            Appleでログイン
          </SigninButton>
        </VStack>
        <Divider mt={6} />
        <Box mt={2}>
          <Text textAlign="center" mb={2}>
            アカウント未登録の方はこちら
          </Text>
          <Button
            as="a"
            href={signupUrl} // アカウント登録は事前のID基盤ログインが不要なためproceedIdPageを使わない
            w="100%"
            variant="outline"
            colorScheme="brand"
            p={6}
          >
            新規会員登録
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;

export const getServerSideProps: GetServerSideProps = withAuth(
  async (context) => {
    if (context.req.isAuthenticated()) {
      return {
        redirect: {
          permanent: false,
          destination: '/',
        },
      };
    }

    return {
      props: {},
    };
  },
);
