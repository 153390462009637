import React, { useCallback, useState } from 'react';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { FieldInputProps } from 'react-final-form';

const PasswordInput = (inputProps: FieldInputProps<string>) => {
  const [show, setShow] = useState(false);
  const handleClick = useCallback(() => setShow(!show), [show]);
  return (
    <InputGroup size="md">
      <Input
        pr="2.5rem"
        type={show ? 'text' : 'password'}
        placeholder="パスワード"
        {...inputProps}
      />
      <InputRightElement>
        <IconButton
          aria-label={show ? 'パスワードを隠す' : 'パスワードを表示する'}
          icon={show ? <ViewOffIcon /> : <ViewIcon />}
          onClick={handleClick}
          borderLeftRadius="none"
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
